@font-face {
  font-family: "Barricada";
  src: url("./assets/fonts/Barricada.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/images/background.png);
  background-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app_container {
  /* max-width: 1400px; */
  min-height: 100vh;
  margin: 0 auto;
}

.title {
  font-family: Barricada, Arial, sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 54px;
  line-height: 54px;
}

.image__title {
  max-width: 450px;
  margin: 50px 0px;
}


.subtitle {
  font-family: Barricada, Arial, sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 74px;
  line-height: 74px;
}

.mint__info {
  color: #fffd00;
  font-size: 28px;
  margin-bottom: 100px;
}

.mint_img img {
  max-width: 480px;
}

.button__container {
  width: 280px;
  color: #fff;
  text-align: center;
}

.button {
  width: 100%;
  /* height: 35px; */
  background: #fffd00;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 2px;
  text-transform: uppercase;
  text-align: center;
}

.tokens__select {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select__item {
  width: 25%;
  height: 25px;
  border: 2px solid #fffd00;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fffd00;
  margin-bottom: 10px;
  cursor: pointer;
}

.select__item.active {
  background: #fffd00;
  color: #000;
}

select {
  width: 100%;
  height: 36px;
  padding: 0px 15px;
  background: #fffd00;
  outline: none;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
  margin-bottom: 20px;
}

.home-button {
  width: 90%!important;
  margin-bottom: 20px!important;
}

@media (max-width: 1124px) {
  .mint_img img {
    max-width: 380px;
  }
  .image__title {
    width: 450px;
    margin-bottom: 10px;
  }
}

@media (max-width: 900px) {
  .mint_img img {
    max-width: 220px;
  }

  .title {
    font-size: 34px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 54px;
    line-height: 54px;
  }

  .mint__info {
    font-size: 18px;
  }

  .mobile-center {
    justify-content: center;
    text-align: center;
  }

}

@media (max-width: 700px) {
  .container {
    /* padding: 30px 0px; */
    /* justify-content: flex-end; */
    align-items: center;
    flex-direction: column-reverse;
  }

  .mint__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 40px;
  }

  .image__title {
    width: 320px;
    margin-bottom: 10px;
  }

  .mint__info {
    margin-bottom: 40px;
  }

  .error__text {

  }

  .mint_img img {
    max-width: 220px;
    margin-bottom: 40px;
  }
    
  .home-button {
    /* width: 100%; */
    margin: 0 auto;
  }
}

.stakingImage {
  max-width: 90%;
  object-fit: contain;
  max-height: 120px;
}

.coin__image {
  /* position: absolute; */
  width: 70%;
}

.text__normal {
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.yellow-title {
  font-size: 40px;
  line-height: 59px;
  color: #FFF500;
}

.white-title {
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
}
.text-center {
  text-align: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.tokenCard {
  width: 140px;
  width: 140px;
  object-fit: contain;
}